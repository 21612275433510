import React from 'react';
import './Team.css';

const teamMembers = [
    {
        name: 'Yohann Pedrini',
        role: 'Chef d\'atelier',
        photo: 'https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/Images/Avatar_1.webp'
    },
    {
        name: 'Chloé Pedrini',
        role: (
            <>
                Responsable administratif
                <br />
                et vente
            </>
        ),
        photo: 'https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/Images/Avatar_2.webp'
    },
    {
        name: 'Igor Santos De Andrade',
        role: 'Mécanicien',
        photo: 'https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/Images/Avatar_1.webp'
    },
    {
        name: 'Quentin Dupuis',
        role: 'Apprenti mécanicien',
        photo: 'https://garagedufuniculaire.ch/funiculaire/src/Funiculaire/Images/Avatar_1.webp'
    },
];

function Team() {
    return (
        <div className="background-team">
            <div className='container-team'>
                <h1>Notre équipe</h1>
                <p>
                    Nous sommes fiers de vous présenter notre équipe dévouée et talentueuse. Chaque membre de notre équipe
                    apporte une richesse de compétences et d'expertise, travaillant ensemble pour fournir les meilleures solutions
                    et services à nos clients. Découvrez les visages derrière notre succès.
                </p>
                <div className="container-team-member">
                    {teamMembers.map((member, index) => (
                        <div key={index} className="box">
                            <div className='img'>
                                <img src={member.photo} alt="Avatar" />
                            </div>
                            <h2>{member.name}</h2>
                            <h3>{member.role}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Team;
