import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Header.css';

function Header({ onLinkClick }) {
    const [isNavVisible, setIsNavVisible] = useState(false);
    const [isIconTransition, setIsIconTransition] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 900);
    
    const navigate = useNavigate(); 

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 900);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleNav = () => {
        setIsIconTransition(true);
        setIsNavVisible(!isNavVisible);
        setIsIconTransition(false);
    };

    const handleLogoClick = () => {
        if (onLinkClick) {
            onLinkClick();
        }
        setIsNavVisible(false);
        navigate('/');  
    };

    return (
        <header>
            <ul>
                <span className='container-logo' onClick={handleLogoClick}>
                    {isSmallScreen ? (
                        <>
                            <h1>Garage du Funiculaire<br /><span>Sàrl</span></h1>
                        </>
                    ) : (
                        <h1>Garage du Funiculaire Sàrl</h1>
                    )}
                </span>
                <a className='instagram' href="https://www.instagram.com/garage_dufuniculaire_sarl/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                <FontAwesomeIcon 
                    icon={isNavVisible ? faXmark : faBars} 
                    className={`menu-icon ${isIconTransition ? 'rotate' : ''}`} 
                    onClick={toggleNav} 
                />
                <div className={`container-nav ${isNavVisible ? 'visible' : ''}`}>
                    <li>
                        <Link className='link' to="/" onClick={() => { toggleNav(); onLinkClick && onLinkClick(); }}>
                            Accueil
                        </Link>
                    </li>
                    <li>
                        <a className='link' href="https://www.autolina.ch/fr/iframe/48800?border=000000&text=000000&links=000000&icon=000000&buttons=1f1f1f&buttonsText=fefefe&bobg=1f1f1f&garantieFilter=none&footer=block&transparentFooter=true&transparency=transparent" target="_blank" rel="noopener noreferrer" onClick={toggleNav}>
                            Vente d'occasions
                        </a>
                    </li>
                    <li>
                        <a className='link' href="https://www.ebike-riviera.ch/" target="_blank" rel="noopener noreferrer" onClick={toggleNav}>
                            E-bike
                        </a>
                    </li>
                    <li>
                        <a className='link' href="https://riviera-depannage.ch/" target="_blank" rel="noopener noreferrer" onClick={toggleNav}>
                            Dépannage
                        </a>
                    </li>
                    <li>
                        <Link className='link' to="/equipe" onClick={() => { toggleNav(); onLinkClick && onLinkClick(); }}>
                            Notre équipe
                        </Link>
                    </li>
                </div>
            </ul>
        </header>
    );
}

export default Header;
